.headerSidebar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title{
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
}