.search{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputWrap{
    flex: 1;
}

.input{
    width: 100%;
}

.switchers{
    margin-left: 5px;
    margin-right: 5px;
}

.switchersBtn{
    font-size: 24px;
}