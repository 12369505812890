.content{
    padding: 24px 24px 0;
    margin: 64px 0 0 400px;
    min-height: calc(100vh - 64px - 24px);
    max-width: 100vw;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
}

.contentWrap{
    padding: 0 24px;
    box-sizing: border-box;
    min-height: 100vh;
}

.sidebarWrap{
}

@media (max-width: 1200px) {
    .content{
        margin-left: 0;
    }
}
