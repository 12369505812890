.currentInfo {
    margin-top: 18px;
    font-size: 16px;
}

.currentInfo a {
    word-break: break-all;
}

.currentInfo .issuerWrap a {
    word-break: break-word;
}

.issuerWrap {
    max-width: 700px;
}